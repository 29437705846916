var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CModal',{attrs:{"title":_vm.titleModal,"size":"lg","color":_vm.colorModal,"show":_vm.modalLastStatus,"scrollable":""},on:{"update:show":function($event){_vm.modalLastStatus=$event}}},[_c('div',{staticClass:"d-flex justify-content-end mr-3"},[_c('CLink',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Actualiser'),expression:"'Actualiser'"}]},[_c('CButton',{attrs:{"size":"sm","shape":"pill","color":"secondary"},on:{"click":function($event){return _vm.LoadTacheData(false)}}},[_c('CIcon',{attrs:{"name":"cil-reload","size":"sm"}})],1)],1)],1),_c('CRow',[(_vm.type == 'mestaches')?_c('CCol',{attrs:{"col":"4"}},[_c('CSelect',{attrs:{"options":_vm.users,"label":"Nom de l'utilisateur :","placeholder":"Select user tasks"},on:{"update:value":_vm.LoadTacheData},model:{value:(_vm.current_user),callback:function ($$v) {_vm.current_user=$$v},expression:"current_user"}})],1):_vm._e()],1),_c('custom-filter',{attrs:{"itemsLoaded":_vm.itemsTache,"type":_vm.type},on:{"items_loaded_format":_vm.setItemsLoaded}}),_c('CDataTable',{staticClass:"m-0 table-borderless",attrs:{"hover":"","responsive":false,"items":_vm.itemsLoaded,"fields":_vm.tableFields,"header":false,"loading":_vm.isLoading,"items-per-page-select":"","items-per-page":10,"pagination":""},scopedSlots:_vm._u([{key:"user",fn:function(ref){
var item = ref.item;
return _c('td',{},[_c('CLink',{staticClass:"text-decoration-none",attrs:{"to":{
          path: '/projets/' + item.idcontents
        }}},[_c('div',{on:{"click":_vm.evModalLast}},[_vm._v(" "+_vm._s(item.titre)+" "),(item.privaty == '1')?_c('CBadge',{staticClass:"mr-1",attrs:{"color":"danger","position":"top-start","shape":"pill"}},[_vm._v(" Privé ")]):_vm._e(),(item.prime_status == '1')?_c('CBadge',{attrs:{"color":"warning ","position":"top-start","shape":"pill"}},[_vm._v(" Prime ")]):_vm._e()],1),_c('div',{staticClass:"small text-muted mt-1"},[_vm._v(" Crée le: "+_vm._s(item.created_at)+" ")])])],1)}},{key:"usage",fn:function(ref){
        var item = ref.item;
return _c('td',{staticClass:"usage-progress"},[(_vm.type != 'lastupdates')?_c('div',[_vm._v(" "+_vm._s(_vm.progressItem(item))+" "),(item.val && item.max)?_c('CProgress',{staticClass:"progress-xs",staticStyle:{"height":"10px"},attrs:{"animated":item.status === '1' || item.status === '3' ? false : true,"showPercentage":"","striped":item.status === '1' || item.status === '3' ? false : true,"max":item.max,"value":item.val,"color":_vm.color(item.val, item.max)}}):_vm._e()],1):_vm._e(),(_vm.type == 'lastupdates')?_c('div',{staticClass:"clearfix"},[_c('div',{staticClass:"float-left"}),_c('div',{staticClass:"float-right"},[_c('small',{staticClass:"text-bold"},[_c('strong',[_vm._v("Updated: ")]),_vm._v(" "+_vm._s(item.update_at))])])]):_vm._e()])}},{key:"activity",fn:function(ref){
        var item = ref.item;
return _c('td',{},[_c('CRow',{staticClass:"ml-4 d-flex justify-content-arround flex-nowrap"},[_c('CLink',{staticClass:"mx-3",attrs:{"color":"primary","variant":"ghost","shape":"pill","size":"sm"}},[_vm._v(" "+_vm._s(item.type)+" ")])],1)],1)}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }